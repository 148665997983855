import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [`toggle`]
  static classes = [`toggle`]
  declare readonly toggleTargets: HTMLDivElement
  declare readonly toggleClasses: string[]

  toggle() {
    for (const target of this.toggleTargets) {
      for (const string of this.toggleClasses) {
        target.classList.toggle(string)
      }
    }
  }
}
